import React from 'react';
import './Portfolio.scss';
import { Link } from 'react-router-dom';

import imageProfil from '../../images/profil/portfolio.jpg'
import imageBachelor from '../../images/Tisch/portfolio.jpg';
import imageSzenografie from '../../images/Gaststaette/portfolio.jpg';
import imageMessestand from '../../images/Messestand/portfolio.jpg';
import imageTasche from '../../images/Tasche/portfolio.jpg';
import imageSchaukel from '../../images/Hollywood/portfolio.jpg';

import { Row, Col } from 'react-bootstrap';

function PortfolioImage(props) {
  return (
    <Col sm={6} lg={4} className="mt-4">
      <Link to={props.to} className="image">
        <img alt="" src={props.image} />
        <div className="desc">
          <p>
            {props.title}
          </p>
        </div>
      </Link>
    </Col>
  )
}

function Portfolio() {
  return (
    <Row className="portfolio">
      <PortfolioImage to="/ueber-mich" title="Über mich" image={imageProfil} />
      <PortfolioImage to="/bachelorarbeit" title="Bachelorarbeit" image={imageBachelor}  />
      <PortfolioImage to="/szenografie" title="Szenografie" image={imageSzenografie} />
      <PortfolioImage to="/messestand" title="Messestand" image={imageMessestand} />
      <PortfolioImage to="/freddibag" title="Ökodesign" image={imageTasche} />
      <PortfolioImage to="/schaukel" title="Schaukel" image={imageSchaukel} />
    </Row>
  );
}

export default Portfolio;
