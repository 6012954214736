import React from 'react';
import './App.css';
import {Container} from 'react-bootstrap';
import {BrowserRouter, Route} from "react-router-dom";
import Header from './components/header/Header';
import Portfolio from './components/portfolio/Portfolio';
import Impressum from './components/impressum/Impressum';
import Datenschutz from './components/impressum/Datenschutz';
import Footer from './components/Footer';
import UeberMich from './components/ueber-mich/UeberMich';
import Bachelorarbeit from './components/bachelorarbeit/bachelorarbeit';
import Szenografie from './components/szenografie/Szenografie';
import Messestand from './components/messestand/messestand';
import FreddiBag from './components/freddibag/freddibag';
import Schaukel from './components/schaukel/schaukel';

function App() {
    return (
        <div className="app-component">
            <BrowserRouter>
                <Header/>
                <Container className="body">
                    <Route exact path="/">
                        <Portfolio/>
                    </Route>
                    <Route path="/ueber-mich">
                        <UeberMich />
                    </Route>
                    <Route path="/bachelorarbeit">
                        <Bachelorarbeit />
                    </Route>
                    <Route path="/szenografie">
                        <Szenografie />
                    </Route>
                    <Route path="/messestand">
                        <Messestand />
                    </Route>
                    <Route path="/freddibag">
                        <FreddiBag />
                    </Route>
                    <Route path="/schaukel">
                        <Schaukel />
                    </Route>
                    <Route path="/impressum">
                        <Impressum/>
                    </Route>
                    <Route path="/datenschutz">
                        <Datenschutz/>
                    </Route>
                </Container>
                <Footer/>
            </BrowserRouter>
        </div>
    );
}

export default App;
