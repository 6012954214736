import React from 'react';
import { OneImage, TwoImages } from '../Images';
import Page from '../Page';

import image1 from '../../images/Hollywood/tipi_wiege_render.jpg';
import image2 from '../../images/Hollywood/tipi_render.jpg';
import image3 from '../../images/Hollywood/familie.jpg';
import image4 from '../../images/Hollywood/sitzend.jpg';



function Schaukel() {

    var lines = [
        'Die Hollywoodschaukel ist für Kinder entwickelt. Die Seitenstreben können bei Bedarf zusammengeschoben und als Tipi-Spielzelt genutzt werden.',
        'Die Schaukel kann in eine waagerechte Position gebracht werden und dient so als Wiege für Säuglinge.',
        'Die Konstruktion ist ein reines Stecksystem. Zusätzliche Schrauben erhöhen lediglich die Sicherheit.'
    ]

    return (
        <Page title="Kinder-Hollywoodschaukel" year="2016"
            text={lines}>

            <OneImage
                image={image1} alt="Wiege - render" />

            <OneImage
                image={image2} alt="Sitzend - render" />

            <TwoImages
                image1={image3} alt1="Wiege"
                image2={image4} alt2="Sitzend" />

        </Page>
    )
}

export default Schaukel;