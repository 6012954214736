import React, { useEffect, useState } from 'react';

var alleZitate = [
    {
        zitat: "Sie stellt immer einen hohen Qualitätsanspruch an sich selbst.",
        quelle: "Freunde"
    },
    {
        zitat: "Gute Qualität ist ihr wichtig und ihr Stil hat stets eine klassische Note.",
        quelle: "Freunde"
    },
    {
        zitat: "Sie ist sehr treu, sehr ehrlich, ist motiviert, bringt die Sache zuende, die sie beginnt.",
        quelle: "Freunde"
    },
    {
        zitat: "Sie ist nicht nur kreativ und praktisch, sondern auch hilfsbereit und zuverlässig. Es ist einfach mit ihr zu arbeiten und befreundet zu sein.",
        quelle: "Freunde"
    },
    {
        zitat: "Sie ist eine vielseitig begabte und kreative junge Frau mit Herz, einem ausgeprägten Familiensinn und vielen schönen Träumen, deren Erfüllung sie sich erhofft und für die sie bereit ist zu kämpfen.",
        quelle: "Familie"
    },
    {
        zitat: "Sie ist eine gut organisierte, selbstbewusste und wunderhübsche Frau.",
        quelle: "Freunde"
    },
    {
        zitat: "Sie ist eine ruhige, ausgeglichene und immer freundliche Person, die sich nicht aufdrängt und man kann sich super auf sie verlassen.",
        quelle: "Familie"
    },
    {
        zitat: "Kreativ und fantasievoll, einen Blick für den Augenblick und für schönes, sensibel, familienverbunden, naturverbunden, treu und zuverlässig. Handwerklich begabt und geschickt.",
        quelle: "Familie"
    },
];

let timeout = null;

export default function Zitate() {

    const [visible, setVisible] = useState(true);
    const [zitat, setZitat] = useState(0);

    useEffect(() => {
        timeout = setTimeout(() => {
            setVisible(false);

            timeout = setTimeout(() => {
                setZitat(Math.abs((zitat + 1) % alleZitate.length));
                setVisible(true);
            }, 1000);
        }, 5000);

        return () => {
            clearTimeout(timeout);
        };
    }, [zitat]);

    const style = {
        color: "gray"
    };

    var curZitat = alleZitate[zitat];

    return (
        <div className={visible ? 'fadeIn' : 'fadeOut' }>
            <div><i>&quot;{curZitat.zitat}&quot;</i></div>
            <div><i style={style}>{curZitat.quelle}</i></div>
        </div>
    );
}