import React from 'react';
import { Row, Col } from 'react-bootstrap';

function Page(props) {
    const title = props.title;
    const year = props.year;
    const text = props.text;

    return (
        <>
            <Row>
                <Col className="mt-4 mb-4" xs={{ span: 10, offset: 1 }} md={{ span: 12, offset: 0 }} lg={{ span: 8, offset: 2}}>
                    <h3>{title}</h3>
                    

                    {text.map(line => (
                        <div>{line}</div>
                    ))}
                </Col>
            </Row>
            <Row>
                {props.children}
            </Row>
            <Row>
                <Col className="mt-3 text-right" xs={{ span: 10, offset: 1 }} md={{ span: 10, offset: 1 }}><small>{year}</small></Col>
            </Row>
        </>
    )
}

export default Page;