import React from 'react';
import { TwoImages } from '../Images';
import Page from '../Page';

import image1 from '../../images/Messestand/modell.jpg';
import image2 from '../../images/Messestand/draufsicht.jpg';
import image3 from '../../images/Messestand/bar-mit-leuten.jpg';
import image4 from '../../images/Messestand/fotomotiv-mit-menschen.jpg';




function Messestand() {

    var lines = [
        'Der Stand ist in Schwarz/Weiß gehalten, sodass die Kunden das Gefühl bekommen, in einen alten Film aus den 50er Jahren einzutauchen.',
        'Es gibt einen Diner mit Jukebox, hier können Milchshakes und Hotdogs erworben werden. Das Dach dient für Geschäftsgespräche. Der Cadillac in der Ecke wird als Fotoaktion genutzt.',
        'Bei dem Eiswagen auf der Standecke erhalten die Besucher kostenlos Eis, dies dient als Besucher-Stopper.'
    ]

    return (
        <Page title="Rock `n` Roll Musik - Messestand" year="2015"
            text={lines}>
            
            <TwoImages
                image1={image1} alt1="Modell"
                image2={image2} alt2="Cadillac" />

            <TwoImages
                image1={image3} alt1="Bar"
                image2={image4} alt2="Fotomotiv" />

        </Page>
    )
}

export default Messestand;