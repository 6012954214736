import React from 'react';
import { TwoImages } from '../Images';
import Page from '../Page';

import stuehle from '../../images/Gaststaette/stuehle.jpg';
import instrumente from '../../images/Gaststaette/instrumente.jpg';
import beiTag from '../../images/Gaststaette/bei-tag.jpg';
import beiNacht from '../../images/Gaststaette/bei-nacht.jpg';




function Szenografie() {

    var lines = [
        'In dem Roman "Milch und Kohle" von Ralf Rohmann geht es um den Alltag einer typischen Ruhrgebiets-Familie in den 60er Jahren.',
        'Die Gaststätte Maus ist einer der Spielorte. Hier wurde sie im Maßstab 1:15 umgesetzt. Gerade die Lichtstimmung ist ein wichtiges Detail: Bei Tag das Licht durch die bunten Glasscheiben. Nachts das Lichtspiel an den Wänden, erzeugt durchs Scheinwerferlicht auf die Discokugel.'
    ]

    return (
        <Page title="Gaststätte Maus - Bühnenbild" year="2016"
            text={lines}>
            
            <TwoImages
                image1={stuehle} alt1="Stühle"
                image2={instrumente} alt2="Instrumente" />

            <TwoImages
                image1={beiTag} alt1="Bei Tag"
                image2={beiNacht} alt2="Bei Nacht" />

        </Page>
    )
}

export default Szenografie;