import React from 'react';
import './Header.scss';
import imageHeader from '../../images/header.png';
import imageName from '../../images/name.png';
import logo from '../../images/favicon.png';
import {Navbar, Nav} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';

function Header() {

    var headerStyle = {
        backgroundImage: `url(${imageHeader})`
    };

    return (
        <Navbar className="header" collapseOnSelect expand="md" variant="dark" style={headerStyle} fixed="top">
            <LinkContainer to="/">
                <Navbar.Brand>
                    <img
                        alt="Logo"
                        height="40"
                        src={logo}
                        className="d-inline-block d-md-none align-middle mr-2"/>
                </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    <LinkContainer to="/">
                        <Nav.Link>Portfolio</Nav.Link>
                    </LinkContainer>
                </Nav>
                <Nav className="mr-auto d-none d-md-inline">
                    <img
                        alt="Frederika Kortmann"
                        height="50"
                        className="align-midle"
                        src={imageName}/>
                </Nav>
                <Nav>
                    <LinkContainer to="/impressum">
                        <Nav.Link>Impressum</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/datenschutz">
                        <Nav.Link>Datenschutz</Nav.Link>
                    </LinkContainer>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default Header;
