import React from 'react';
import {Kontakt} from '../Kontakt';

function Datenschutz() {
    return (
        <div>
            <h1>Datenschutzerklärung</h1>
            <p>Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der
                EU-Datenschutzgrundverordnung (DSGVO), ist:</p>
            <p><Kontakt showName={true}/></p>
            <h2>Ihre Betroffenenrechte</h2>
            <p>Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie
                jederzeit folgende Rechte ausüben:</p>
            <ul>
                <li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art.
                    15 DSGVO),</li>
                <li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
                <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
                <li>Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund
                    gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),</li>
                <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und</li>
                <li>Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben
                    oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).</li>
            </ul>
            <p>Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit
                mit Wirkung für die Zukunft widerrufen.</p>
            <p>
                Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde
                wenden, z. B. an die zuständige Aufsichtsbehörde des Bundeslands Ihres
                Wohnsitzes oder an die für uns als verantwortliche Stelle zuständige Behörde.</p>
            <p>
                Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit
                Anschrift finden Sie unter: <a
                    className="break-all"
                    href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
                    target="_blank"
                    rel="noreferrer nofollow noopener">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.
            </p>
            <p></p>
            <h2>SSL-Verschlüsselung</h2>
            <p>Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir
                dem aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren (z. B.
                SSL) über HTTPS.
            </p>

            <h2>Änderung unserer Datenschutzbestimmungen</h2>
            <p>Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets
                den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer
                Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer
                Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.
            </p>
            <h2>Fragen an den Datenschutzbeauftragten</h2>
            <p>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail
                oder wenden Sie sich direkt an die für den Datenschutz verantwortliche Person in
                unserer Organisation:
            </p>
            <p>
                <Kontakt showName={true}/>
            </p>
            <p>
                <em>Die Datenschutzerklärung wurde mithilfe der activeMind AG erstellt, den
                    Experten für <a
                        className="break-all"
                        href="https://www.activemind.de/datenschutz/datenschutzhinweis-generator/"
                        target="_blank"
                        rel="noreferrer nofollow noopener">externe Datenschutzbeauftragte</a> (Version #2019-04-10).
                </em>
            </p>
        </div>
    );
}

export default Datenschutz;
