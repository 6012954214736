import React from 'react';
import { Email } from './Kontakt';

function Footer() {
    var year = new Date().getFullYear();

    return (
        <div className="footer text-sm-right m-2 mt-4">
            <Email /> | Frederika Kortmann, Copyright &copy; {year}
        </div>
    );
}

export default Footer;
