import React from 'react';
import profilImage from '../../images/profil/ich.jpg';

import { Row, Col } from 'react-bootstrap';
import Zitate from './Zitate';

function UeberMich() {
    return (
        <>
            <Row>
                <Col className="mt-4 mb-5" xs={{ span: 12, offset: 0 }} sm={{ span: 8, offset: 2 }} md={{ span: 6, offset: 3 }} lg={{ offset: 4, span: 4 }}>
                    <img style={{ width: "100%" }} alt="Profilbild" src={profilImage} />
                </Col>
            </Row>
            <Row>
                <Col xs={{ span: 12, offset: 0 }} md={{ span: 8, offset: 2 }} lg={{ offset: 3, span: 6 }}>
                    <div>
                        <div><b>Abschl&uuml;sse</b></div>
                        <ul>
                            <li>Bachelor of Arts, Schwerpunkt Objekt- und Raumdesign</li>
                            <li>Staatlich gepr&uuml;fte gestaltungstechnische Assistentin, Schwerpunkt Grafik- und Objektdesign</li>
                        </ul>
                    </div>

                    <div><b>&Uuml;ber mich</b></div>
                    <ul>
                        <li>j&uuml;ngste von drei Geschwistern</li>
                        <li>gro&szlig; geworden im sch&ouml;nen L&uuml;dinghausen, M&uuml;nsterland</li>
                        <li>Abitur und GTA-Ausbildung im Sauerland absolviert</li>
                        <li>Studium im Herzen Dortmunds mit Bachelor abgeschlossen</li>
                        <li>erste Erfahrungen im Berufsleben in Nebenjobs w&auml;hrend des Studiums
                gesammelt, bei Ikea und bei Vislide (Agentur f&uuml;r Fotoaktionen)</li>
                        <li>zur Zeit arbeite ich bei der N&auml;hszene (Stoffladen) in Iserlohn, im Bereich
                          Einzelhandel (Kundenberatung) und Logistik (Lagerarbeit und Shuttlefahrten
                zwischen den Filialen)</li>
                    </ul>

                    <div className="mt-5"><b>Zitate &uuml;ber mich</b></div>
                    <Zitate />
                </Col>

            </Row>
        </>
    );
}

export default UeberMich;