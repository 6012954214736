import React from 'react';
import { TwoImages } from '../Images';
import Page from '../Page';

import zusammengeschoben from '../../images/Tisch/zusammengeschoben.jpg';
import ausgeklappt from '../../images/Tisch/ausgeklappt.jpg';

import schreibplatte from '../../images/Tisch/schreibplatte.jpg';
import korkplatte from '../../images/Tisch/korkplatte.jpg';

import seitenfach from '../../images/Tisch/seitenfach.jpg';
import oben from '../../images/Tisch/oben.jpg';

import platteGekippt from '../../images/Tisch/platte-gekippt.jpg';
import steckdose from '../../images/Tisch/steckdose.jpg';

import rendering from '../../images/Tisch/Rendering.jpg';
import renderingAusgezogen from '../../images/Tisch/RenderingAusgezogen.jpg';


function Bachelorarbeit() {

    var lines = [
        "Der Tisch lässt sich einfach vergrößern, durch das Ausziehen der Beine und das Auflegen des Seitenteils.",
        "An Stelle der Grundplatte können unterschiedliche Unterlagen eingelegt werden, für´s Schreiben, Schneiden oder Abpausen.",
        "Viele verschiedene Schubladen und Fächer bieten Platz für Schreibutensilien und Papiere.",
        "Durch Schrägstellung der Grundplatte wird das Arbeiten am Laptop rückenschonend.",
        "Der Stromanschluss im Tisch versteckt den Kabelsalat von Lampe, Laptop, etc."
    ]

    return (
        <Page title="Der Arbeitstisch / Bachelorarbeit" year="2017"
            text={lines}>
            <TwoImages 
                image1={zusammengeschoben} alt1="Tisch zusammengeschoben"
                image2={rendering} alt2="Tisch zusammengeschoben (Rendering)" />

            <TwoImages 
                image1={ausgeklappt} alt1="Tisch ausgeklappt"
                image2={renderingAusgezogen} alt2="Tisch ausgeklappt (Rendering)" />

            <TwoImages
                image1={schreibplatte} alt1="Schreibplatte"
                image2={korkplatte} alt2="Korkplatte" />

            <TwoImages
                image1={seitenfach} alt1="Seitenfach"
                image2={oben} alt2="Ansicht von oben" />

            <TwoImages
                image1={platteGekippt} alt1="Platte gekippt"
                image2={steckdose} alt2="Steckdose" />


        </Page>
    )
}

export default Bachelorarbeit;
