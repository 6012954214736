import React from 'react';
import { TwoImages, ThreeImages } from '../Images';
import Page from '../Page';

import image1 from '../../images/Tasche/blau-vorne.jpg';
import image2 from '../../images/Tasche/rosa-seite.jpg';
import image3 from '../../images/Tasche/gelb-seite.jpg';
import image4 from '../../images/Tasche/blau.jpg';
import image5 from '../../images/Tasche/weiss-hinten.jpg';




function FreddiBag() {

    var lines = [
        'Die Idee hinter der Tasche war es, eine umweltfreundliche Alternative für die Plastiktüte zu schaffen. Die Tasche besteht aus Bio-Leinen, der in Norddeutschland angebaut und gewoben wird und mit heimischen, wilden Pflanzen gefärbt wurde. Sie ist nicht nur besonders Platz sparend verstaubar, z.B. in der Handtasche, sondern auch ein wahres Leichtgewicht mit ca. 170 Gramm.',
        'Die Tasche wird durch Umschnallen der Griffe in einen Rucksack verwandelt. So können die Einkäufe rückenfreundlich transportiert werden.'
    ]

    return (
        <Page title="FreddiBag - Ökodesign" year="2016"
            text={lines}>
            
            <TwoImages
                image1={image1} alt1="Blaue Tasche"
                image2={image2} alt2="Rosa Tasche" />

            <ThreeImages
                image1={image3} alt1="Gelbe Tasche"
                image2={image4} alt2="Blaue Tasche"
                image3={image5} alt3="Weiße Tasche" />

        </Page>
    )
}

export default FreddiBag;