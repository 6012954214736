import React from 'react';
import { Row, Col } from 'react-bootstrap';

const imgStyle = {
    width: "100%"
};

function OneImage(props) {
    return (
        <Row>
            <Col className="mt-3" xs={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} >
                <img style={imgStyle} src={props.image} alt={props.alt} />
            </Col>
        </Row>
    );
}


function TwoImages(props) {
    return (
        <Row>
            <Col className="mt-3" xs={{ span: 10, offset: 1 }} md={{ span: 6, offset: 0 }} lg={{ span: 4, offset: 2 }} >
                <img style={imgStyle} src={props.image1} alt={props.alt1} />
            </Col>
            <Col className="mt-3" xs={{ span: 10, offset: 1 }} md={{ span: 6, offset: 0 }} lg={{ span: 4, offset: 0 }} >
                <img style={imgStyle} src={props.image2} alt={props.alt2} />
            </Col>
        </Row>
    );
}

function ThreeImages(props) {
    return (
        <Row>
            <Col className="mt-3" xs={{ span: 10, offset: 1 }} md={{ span: 4, offset: 0 }} lg={{ span: 4, offset: 0 }} >
                <img style={imgStyle} src={props.image1} alt={props.alt1} />
            </Col>
            <Col className="mt-3" xs={{ span: 10, offset: 1 }} md={{ span: 4, offset: 0 }} lg={{ span: 4, offset: 0 }} >
                <img style={imgStyle} src={props.image2} alt={props.alt2} />
            </Col>
            <Col className="mt-3" xs={{ span: 10, offset: 1 }} md={{ span: 4, offset: 0 }} lg={{ span: 4, offset: 0 }} >
                <img style={imgStyle} src={props.image3} alt={props.alt3} />
            </Col>
        </Row>
    );
}

export {
    OneImage,
    TwoImages,
    ThreeImages
}